import envValues from '../enviornment';

export const SERVER_URL = `${envValues.REACT_APP_API_ENDPOINT}/auth`;
export const SERVER_BASE_URL = envValues.REACT_APP_API_ENDPOINT;
export const ERROR_MESSAGE = 'Api Fetch Error!';
export const ENCRYPT_SECERET_KEY = 'armia-secret-key@123';
export const REACT_APP_API_POST_USER = `${envValues.REACT_APP_API_ENDPOINT}/user`;
export const REACT_APP_API_FETCH_USER = `${envValues.REACT_APP_API_ENDPOINT}/admin/users?sortBy=createdAt:desc&`;
export const REACT_APP_API_FETCH_ADMIN = `${envValues.REACT_APP_API_ENDPOINT}/admin?`;
export const REACT_APP_API_FETCH_FEEDBACKS = `${envValues.REACT_APP_API_ENDPOINT}/feedback?`;
export const REACT_APP_API_FETCH_SUPPORT = `${envValues.REACT_APP_API_ENDPOINT}/support?`;
export const REACT_APP_API_FETCH_SUBSCRIBED = `${envValues.REACT_APP_API_ENDPOINT}/subscription?active=true&`;
export const REACT_APP_API_FETCH_TRANSACTIONS = `${envValues.REACT_APP_API_ENDPOINT}/transaction?`;
export const REACT_APP_API_FETCH_EMPLOYEE = `${envValues.REACT_APP_API_ENDPOINT}/employee?active=true&`;
export const REACT_APP_API_FETCH_ROLE = `${envValues.REACT_APP_API_ENDPOINT}/roles?active=true&`;
export const REACT_APP_API_FETCH_PLANS = `${envValues.REACT_APP_API_ENDPOINT}/stripe/plan?`;
export const REACT_APP_API_SKILLS = `${envValues.REACT_APP_API_ENDPOINT}/dummy/selectdata`;
export const REACT_APP_API_ROLES_DROPDOWN = `${envValues.REACT_APP_API_ENDPOINT}/roles/select`;
export const REACT_APP_API_FETCH_MOVIES = `${envValues.REACT_APP_API_ENDPOINT}/movies?active=true&`;
export const REACT_APP_API_FETCH_NOTIFICATIONS = `${envValues.REACT_APP_API_ENDPOINT}/notification?`;
