import React from 'react';
import PropTypes from 'prop-types';
import useToggle from '../../hooks/useToggle';

function PasswordField({
  label,
  className,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  eyeiconstyle,
  fieldName,
  register,
  errors,
  placeHolder,
  isRequired,
  minimLength,
  maximLength = 15,
  mandatory,
  hideErrors,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
    }
  };

  const [type, setType] = useToggle();

  return (
    <div className="form-group" style={{ position: 'relative' }}>
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ''}
      </label>
      {mandatory || isRequired ? (
        <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
      ) : (
        <span />
      )}
      <div style={{ position: 'relative' }}>
        <input
          type={type ? 'text' : 'password'}
          className={classNamess ?? className}
          onKeyDown={handleKeyDown}
          placeholder={placeHolder}
          data-toggle="tooltip"
          data-placement="top"
          maxLength={maximLength}
          title="Password must include uppercase, lowercase letters, numbers, and special characters"
          style={{
            paddingRight: '30px',
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(fieldName, {
            required: {
              value: isRequired,
              message: `${label} is required.`,
            },
            minLength: {
              value: hideErrors ? 0 : minimLength,
              message: `${label} must be minimum ${minimLength ?? 8}.`,
            },
            pattern: {
              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9\s]).{8,}$/,
              message: `${label} must include uppercase, lowercase letters, numbers, and special characters for better security.`,
            },
          })}
        />
        <span
          role="button"
          tabIndex={0}
          className={eyeiconstyle ?? 'password_show'}
          onClick={setType}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setType();
            }
          }}
          style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)' }}
        >
          {!type ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
        </span>
      </div>
      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
          fontFamily: 'SFUIDisplayRegular',
        }}
      >
        {' '}
        {errors[fieldName] && errors[fieldName].message}{' '}
      </p>
    </div>
  );
}

PasswordField.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string.isRequired,
  eyeiconstyle: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  placeHolder: PropTypes.string,
  isRequired: PropTypes.bool,
  minimLength: PropTypes.number,
  maximLength: PropTypes.number,
  labelstyle: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
  hideErrors: PropTypes.bool,
};
PasswordField.defaultProps = {
  mandatory: false,
  isRequired: false,
  labeldarkstyle: null,
  classNamedark: null,
  maximLength: 15,
  label: 'Password',
  placeHolder: 'Password',
  minimLength: 8,
  hideErrors: false,
};
export default PasswordField;
