import React from 'react';
import PropTypes from 'prop-types';

function NumberInput({
  className,
  labelstyle,
  labeldarkstyle,
  classNamedark,
  fieldName,
  register,
  errors,
  isRequired,
  placeHolder,
  maximLength,
  minimLength,
  label,
  mandatory,
}) {
  let classNames = labelstyle;
  let classNamess = className;

  if (localStorage.getItem('theme') === 'dark') {
    classNames = labeldarkstyle;
    classNamess = classNamedark;
  }
  return (
    <div className="form-group">
      <label htmlFor={fieldName} className={classNames ?? labelstyle}>
        {label ?? ''}
      </label>
      {mandatory || isRequired ? (
        <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
      ) : (
        <span />
      )}
      <input
        type="text"
        placeholder={placeHolder}
        className={classNamess ?? className}
        onKeyPress={(event) => {
          if (!/^[0-9]/.test(event.key)) {
            event.preventDefault();
            errors[fieldName].message = 'Cannot accept characters.';
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(fieldName ?? 'Number', {
          required: {
            value: isRequired,
            message: `${label} is required.`,
          },
          maxLength: {
            value: maximLength ?? 10,
            message: `Value must be maximum ${maximLength ?? 10}.`,
          },
          minLength: {
            value: minimLength ?? 2,
            message: `Value must be minimum ${minimLength ?? 2}.`,
          },
        })}
      />

      <p
        style={{
          color: 'red',
          marginTop: '5px',
          fontSize: '14px',
          fontFamily: 'SFUIDisplayRegular',
        }}
      >
        {' '}
        {
          // Shows if error exist
          errors[fieldName] && errors[fieldName].message
        }{' '}
      </p>
    </div>
  );
}

NumberInput.propTypes = {
  fieldName: PropTypes.element.isRequired,
  className: PropTypes.string,
  register: PropTypes.element.isRequired,
  errors: PropTypes.element.isRequired,
  labelstyle: PropTypes.string.isRequired,
  placeHolder: PropTypes.element,
  isRequired: PropTypes.element,
  maximLength: PropTypes.element,
  minimLength: PropTypes.element,
  label: PropTypes.element,
  labeldarkstyle: PropTypes.string,
  classNamedark: PropTypes.string,
  mandatory: PropTypes.element,
};
NumberInput.defaultProps = {
  mandatory: false,
  isRequired: false,
  placeHolder: 'Enter Value',
  classNamedark: null,
  labeldarkstyle: null,
  maximLength: 10,
  minimLength: 0,
  label: 'Number Input',
  className: 'default-input-class',
};
export default NumberInput;
