/* eslint-disable react/function-component-definition */
import React, { useContext, useEffect, useState } from 'react';
// import moment from 'moment';
import { useForm } from 'react-hook-form';
// import { Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { Entry } from '../../redux/entrySlice';
import styles from './editPlan.module.scss';
import { TableContext } from '../../contexts/tableContext';
import 'react-toastify/dist/ReactToastify.css';
import TextInput from '../../components/TextField/TextField';
import NumberInput from '../../components/NumberBox/NumberBox';
import SelectBox from '../../components/SelectBox/SelectBox';
import TextAreaField from '../../components/TextArea/TextArea';

const PlanEdit = React.memo(() => {
  const interval = [
    { label: 'Choose interval', value: '' },
    { label: 'Monthly', value: 'month' },
    { label: 'Yearly', value: 'year' },
    { label: 'Daily', value: 'day' },
  ];
  let planId;
  const [submiting, setSubmitting] = useState(false);
  const { dashboardStyle } = useContext(TableContext);
  // const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const params = useParams();
  const [plan, setPlan] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Edit Plan Details');
    planId = params.productId;

    const actionData = {};
    actionData.actionUrl = `stripe/plan/${planId}`;
    actionData.actionMethod = 'get';
    dispatch(Entry(actionData)).then((resp) => {
      // delete resp.payload.id;
      setPlan(resp.payload.data.response.result);
      reset(resp.payload.data.response.result);
    });
  }, []);
  // eslint-disable-next-line no-unused-vars
  function onSubmitdata(data) {
    if (data.name.trim().length === 0) {
      document.getElementById('name').focus();
    } else {
      setSubmitting(true);
      setError(null);
      const apiData = {
        name: data.name,
        description: data.description,
        price: data.price,
        interval: data.interval.value || plan.interval,
        formLimit: data.formLimit,
        responseLimit: data.responseLimit,
      };
      data.actionUrl = `stripe/plan/${params.productId}`;
      data.actionMethod = 'patch';
      data.apiData = apiData;
      dispatch(Entry(data)).then((resp) => {
        setSubmitting(false);
        if (resp.payload.code === 200) {
          navigate(-1);
          toast.success('Plan updation successfull');
          // eslint-disable-next-line max-len
        } else if (
          resp.payload.code === 401 ||
          resp.payload.code === 400 ||
          resp.payload.code === 403
        ) {
          setError(resp.payload.message);
          toast.error('Error');
        } else {
          navigate(-1);
        }
      });
    }
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    errors={errors}
                    minimLength="3"
                    isRequired
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <TextAreaField
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Description"
                    placeHolder="Enter description"
                    fieldName="description"
                    register={register}
                    errors={errors}
                    minimLength="10"
                    isRequired
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Price"
                    placeHolder="Enter price"
                    fieldName="price"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <SelectBox
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    name="interval"
                    label="Interval"
                    labelstyle={styles.formlabel}
                    control={control}
                    register={register}
                    values={interval}
                    errors={errors}
                    placeholder="Choose interval"
                    defaultvalue={plan.interval}
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Form Limit"
                    placeHolder="Enter form limit"
                    fieldName="formLimit"
                    register={register}
                    errors={errors}
                    isRequired
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <NumberInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Response Limit"
                    placeHolder="Enter response limit"
                    fieldName="responseLimit"
                    register={register}
                    errors={errors}
                    isRequired
                  />
                </div>
              </div>
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Update'}
                />
              </div>
              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PlanEdit;
