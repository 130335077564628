import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { getCookies } from '../../hooks/useCookies';
import styles from './ToggleButton.module.scss';
import envValues from '../../enviornment';

function ToggleButtonOnOff({ value, id, section }) {
  const [isOff, setIsOff] = useState(value);

  useEffect(() => {
    setIsOff(value);
  }, [value]);

  const handleToggleChange = (newValue) => {
    const message = newValue
      ? 'Are you sure you want to activate this user?'
      : 'Are you sure you want to deactivate this user?';

    confirmAlert({
      title: 'Confirm Status Change',
      message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            const headers = {
              'Content-Type': 'application/json; charset=utf-8',
              'Access-Control-Allow-Origin': '*',
            };
            const body = { token: getCookies('Token'), userid: id, active: newValue };
            const endpoint =
              section === 'User'
                ? `${envValues.REACT_APP_API_ENDPOINT}/auth/user-toggle-change`
                : `${envValues.REACT_APP_API_ENDPOINT}/auth/toggle-change`;

            axios
              .post(endpoint, body, { headers })
              .then(() => setIsOff(newValue))
              .catch((error) => {
                console.error('Toggle failed: ', error);
              });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  const handleChange = () => {
    handleToggleChange(!isOff);
  };

  return (
    <div className="col">
      <label className={styles.switch}>
        <input type="checkbox" checked={isOff} onChange={handleChange} />
        <span className={styles.slider} />
      </label>
    </div>
  );
}

ToggleButtonOnOff.propTypes = {
  value: PropTypes.bool,
  id: PropTypes.string,
  section: PropTypes.string.isRequired,
};

ToggleButtonOnOff.defaultProps = {
  value: false,
  id: null,
};

export default ToggleButtonOnOff;
