/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable global-require */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { firebaseLogin } from 'bubble-chat-web';
import { TableContext } from '../../contexts/tableContext';
import PasswordField from '../../components/PasswordField/PasswordField';
import { logIn } from '../../redux/LoginSlice';
import { Entry } from '../../redux/entrySlice';

// import ProfilePic from './profilePicture/profilePicComponent';
import EmailInput from '../../components/EmailBox/EmailBox';
import styles from './logIn.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { setCookies } from '../../hooks/useCookies';

function AutoLogin() {
  // eslint-disable-next-line no-unused-vars
  const { errormsgStyle, setUserMenus } = useContext(TableContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);
  const [submiting, setSubmitting] = useState(true);

  function CookiesClearedError(value) {
    return (
      value === 'true' &&
      confirmAlert({
        title: 'We use cookies',
        message:
          'We use cookies for the smooth working of this website.You are logged out as you cleared those cookies!',
        buttons: [
          {
            label: 'Ok',
            onClick: () => sessionStorage.setItem('cookiesCleared', false),
          },
        ],
      })
    );
  }
  const getSettings = () => {
    const data = {
      actionUrl: 'settings/getLogo',
      actionMethod: 'get',
    };
    dispatch(Entry(data)).then((resp) => {
      setCookies('dateFormat', resp.payload.dateFormat);
      setCookies('SITE_SETTINGS', JSON.stringify(resp.payload));
      setLogo(resp.payload.logo);
    });
  };
  const onSubmit = () => {
    const data = {
      email: 'admin@formkit.com',
      Password: 'admin123',
    };
    dispatch(logIn(data)).then(async (resp) => {
      if (resp.payload.code === 200) {
        setSubmitting(true);
        setUserMenus(resp.payload.data.userMenu);
        navigate(resp.payload.data.userMenu[0].urlPath);
        const email2 = data.email;
        const password2 = data.Password;
      }
    });
  };
  useEffect(() => {
    getSettings();
    CookiesClearedError(sessionStorage.getItem('cookiesCleared'));
    onSubmit();
  }, []);
  // eslint-disable-next-line no-unused-vars
  const { loading, loginData, error } = useSelector((state) => ({ ...state.app }));

  return (
    <div>
      {submiting ? (
        <div className={styles.loaderOverlay}>
          <div className={styles.loader} />
        </div>
      ) : (
        <p> </p>
      )}
    </div>
  );
}
export default AutoLogin;
